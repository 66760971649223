import React, { useState } from 'react';

function QuestionApp() {
  const questions = [
    {"id": "buildingtype", "type": "choice", "title": "Building Type", "answers": ["Pre-War", "Post-War", "Loft"]},
    {"id": "landmarkeddistrict", "type": "choice", "title": "Landmarked District", "answers": ["Yes", "No"]},
    {"id": "apartmenttype", "type": "choice", "title": "Apartment Type", 'answers' : ["One Story", "Existing Duplex", "New Duplex Combination"]},
    {"id": "squarefootage", "type": "number", "title" : "Square Footage", "config":{"min":1400, "max": 3500, "step": 100}, "answer": 1400},
    {"id": "windows", "type": "choice", "title": "Window Package", "answers": ["Post-War Aluminum", "Pre-War Wood", "LPC-Aproved Metal", "Not Sure"]},
    {"id": "centralac", "type": "choice", "title": "Central AC", "answers": ["Central Air", "PTAC System", "Through Wall"]},
    {"id": "premiumappliance", "type": "choice", "title": "Premium Appliance Package", "answers": ["Yes", "No"]},
    {"id": "motorizedshades", "type": "conditional", "title": "Motorized Shades", "questions": ["motorizedshadesnumber"]},
  ];
  const [answers, setAnswers] = useState({});
  for (const question of questions) {
    if (question['answer'] !== undefined) {
      answers[question['id']] = question['answer'];
    }
  }
  const internalQuestions = {
    "motorizedshadesnumber" : {"id": "motorizedshadesnumber", "type": "number", "title": "Motorized Shades", "config":{"min":2, "max": 18, "step": 1}, "answer": 4}
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showTable, setShowTable] = useState(false);

  function handleNext () {
    if (currentIndex + 2 >= questions.length) {
      costCalculator();
      setShowTable(true);
    } else {
      setCurrentIndex(currentIndex + 2);
    }
  };

  function handleBack() {
    if (showTable) {
      setShowTable(false);
    } else {
      setCurrentIndex(currentIndex - 2);
    }
  }

  function handleAnswerChange (id, value) {
    setAnswers({...answers, [id]: value});
  };

  function handleCheckboxChange (ids, checked) {
    for (const id of ids) {
      if (checked) {
        const targets = document.getElementsByName(id);
        for (const target of targets) {
          target.disabled = false;
          answers[id] = target.value;
        }
      }
      else {
        const targets = document.getElementsByName(id);
        for (const target of targets) {
          target.disabled = true;
        }
        delete answers[id];
      }
    }
  };

  function costCalculator () {
    const costs = {'custom': {}, 'dfa': {}};
    
    if (answers['buildingtype'] === "Pre-War") {
      costs['dfa']['main'] = 490 * answers['squarefootage'];
      costs['custom']['main'] = 398 * answers['squarefootage'];
    }
    else if (answers['buildingtype'] === "Post-War") {
      costs['dfa']['main'] = 421 * answers['squarefootage'];
      costs['custom']['main'] = 352 * answers['squarefootage'];
    }
    else if (answers['buildingtype'] === "Loft") {
      costs['dfa']['main'] = 364 * answers['squarefootage'];
      costs['custom']['main'] = 498 * answers['squarefootage'];
    }
    
    if (answers['landmarkeddistrict'] === "Yes") {
      costs['custom']['main'] = costs['custom']['main'] + (11 * answers['squarefootage']);
    }

    // Skipped apartment type
    costs['dfa']['windows'] = 0;
    if (answers['windows'] === "Pre-War Aluminum") {
      costs['custom']['main'] = costs['custom']['main'] + (3000);
      answers['custom']['windows'] = 3000;
      
    }
    else if (answers['windows'] === "Pre-War Wood") {
      costs['custom']['main'] = costs['custom']['main'] + (4400);
      costs['custom']['windows'] = 4400;
    }
    else if (answers['windows'] === "LPC-Aproved Metal") {
      costs['custom']['main'] = costs['custom']['main'] + (8000);
      costs['custom']['windows'] = 8000;
    }
    else if (answers['windows'] === "Not Sure") {  
      costs['custom']['main'] = costs['custom']['main'] + (5000);
      costs['custom']['windows'] = 5000;
    }

    costs['dfa']['centralac'] = 0;
    if (answers['centralac'] === "Central Air") {
      costs['custom']['main'] = costs['custom']['main'] + (55*answers['squarefootage']);
      costs['custom']['centralac'] = 55*answers['squarefootage'];
    }
    else if (answers['centralac'] === "Through Wall") {
      costs['custom']['main'] = costs['custom']['main'] + (4950);
      costs['custom']['centralac'] = 4950;
    }
    else if (answers['centralac'] === "PTAC System") {
      costs['custom']['main'] = costs['custom']['main'] + (7150);
      costs['custom']['centralac'] = 7150;
    }
   
    if (answers['premiumappliance'] === "Yes") {
      costs['custom']['main'] = costs['custom']['main'] + (16500);
      costs['custom']['premiumappliance'] = 16500;
      costs['dfa']['main'] = costs['dfa']['main'] + (16500);
      costs['dfa']['premiumappliance'] = 16500;
    }

    if (answers['motorizedshades'] !== undefined) {
      costs['custom']['main'] = costs['custom']['main'] + (2200*answers['motorizedshades']);
      costs['custom']['motorizedshades'] = 2200*answers['motorizedshades'];
      costs['dfa']['main'] = costs['dfa']['main'] + (2200*answers['motorizedshades']);
      costs['dfa']['motorizedshades'] = 2200*answers['motorizedshades'];
    }

    // Contingency
    costs['custom']['main'] = costs['custom']['main'] + costs['custom']['main'] * 0.10;
    costs['dfa']['main'] = costs['dfa']['main'] + costs['dfa']['main'] * 0.10;
    costs['custom']['contingency'] = costs['custom']['main'] * 0.10;
    costs['dfa']['contingency'] = costs['dfa']['main'] * 0.10;

    //Change Orders
    costs['custom']['main'] = costs['custom']['main'] + costs['custom']['main'] * 0.02;
    costs['dfa']['main'] = costs['dfa']['main'] + costs['dfa']['main'] * 0.02;
    costs['custom']['changeorders'] = costs['custom']['main'] * 0.02;
    costs['dfa']['changeorders'] = costs['dfa']['main'] * 0.02;

    //Architect's Fee
    costs['custom']['main'] = costs['custom']['main'] + costs['custom']['main'] * 0.20;
    costs['custom']['architectfee'] = costs['custom']['main'] * 0.20;
    costs['dfa']['architectfee'] = 0;


    // Skipped Mechanical Engineering

    if (answers['apartmenttype'] === "New Duplex Combination") {
      costs['custom']['main'] = costs['custom']['main'] + (costs['custom']['main'] * 0.10);
      costs['dfa']['main'] = costs['dfa']['main'] + (costs['dfa']['main'] * 0.10);
      costs['custom']['newduplex'] = costs['custom']['main'] * 0.10;
      costs['dfa']['newduplex'] = costs['dfa']['main'] * 0.10;
    }

    // Fees DOB
    costs['custom']['main'] = costs['custom']['main'] + 15000;
    costs['custom']['dob'] = 15000;
    costs['dfa']['dob'] = 0;
      
    setAnswers({...answers, ...costs});
  }

  const renderQuestion = (question, disabled) => {
    if (question['type'] === "choice") {
      return (
        <div className="btn" role="group">
        {question['answers'].map((choice) => (
          <label key={choice} className="btn btn-secondary">
            <input type="radio" autoComplete="off" name={question['id']} id={choice} onChange={(e) => handleAnswerChange(question['id'], choice)} disabled={disabled}/> {choice}
          </label>
        ))}
        </div>
      )
    } else if (question['type'] === "number") {
      return (
        <select name={question['id']} className="form-select" onChange={(e) => handleAnswerChange(question['id'], e.target.value)} disabled={disabled}>
          {Array.from({length: (question['config']['max'] - question['config']['min']) / question['config']['step'] + 1}, (_, i) => question['config']['min'] + (i * question['config']['step'])).map((choice) => (
            <option key={choice} value={choice} selected={choice === question['answer']}>{choice}</option>
          ))}
        </select>
      )
    }
  }

  function renderNumber(number) {
    const USDollar = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD', maximumFractionDigits: 0});
    if (USDollar.format(number) === "$0" || USDollar.format(number) === "$NaN") {
      return "";
    }
    return USDollar.format(number);
  }

  const renderCosts = () => {
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th><h2>Custom</h2></th>
              <th><h2>DFA</h2></th>
            </tr>
          </thead>
          <tbody>
              <tr>
                <td>Total</td>
                <td>{renderNumber(answers['custom']['main'])}</td>
                <td>{renderNumber(answers['dfa']['main'])}</td>
              </tr>
              <tr>
                <td>Realized Savings</td>
                <td></td>
                <td>{renderNumber(answers['custom']['main'] - answers['dfa']['main'])}</td>
              </tr>

              <tr data-bs-toggle="collapse" data-bs-target=".section-bc">
                <td>
                  <strong>Base Construction</strong>
                  <span class="seemore">+</span>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr className='collapse section-bc subsection'>
                <td>Cost/Sq.Ft.</td>
                <td>{renderNumber(answers['custom']['main']/answers['squarefootage'])}</td>
                <td>{renderNumber(answers['dfa']['main']/answers['squarefootage'])}</td>
              </tr>

              <tr className='collapse section-bc subsection'>
                <td>Construction</td>
                <td>{renderNumber(answers['custom']['main'])}</td>
                <td>{renderNumber(answers['dfa']['main'])}</td>
              </tr>

              {answers['apartmenttype'] === "New Duplex Combination" || answers['apartmenttype'] === "Existing Duplex" ? (
              <tr className='collapse section-bc subsection'>
                <td>Landmarks</td>
                <td></td>
                <td></td>
              </tr>
              ) : ( null )}

              
              <tr data-bs-toggle="collapse" data-bs-target=".section-aa">
                <td>
                  <strong>Add-On Allowances</strong>
                  <span class="seemore">+</span>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr className='collapse section-aa subsection '>
                <td>Window Package</td>
                <td>{renderNumber(answers['custom']['windows'])}</td>
                <td>{renderNumber(answers['dfa']['windows'])}</td>
              </tr>

              <tr className='collapse section-aa subsection'>
                <td>Central AC</td>
                <td>{renderNumber(answers['custom']['centralac'])}</td>
                <td>{renderNumber(answers['dfa']['centralac'])}</td>
              </tr>

              <tr className='collapse section-aa subsection'>
                <td>Premium Appliance Package</td>
                <td>{renderNumber(answers['custom']['premiumappliance'])}</td>
                <td>{renderNumber(answers['dfa']['premiumappliance'])}</td>
              </tr>

              <tr className='collapse section-aa subsection'>
                <td>Motorized Shades</td>
                <td>{renderNumber(answers['custom']['motorizedshades'])}</td>
                <td>{renderNumber(answers['dfa']['motorizedshades'])}</td> 
              </tr>

              <tr> 
                <td>Contingency</td>
                <td>{renderNumber(answers['custom']['contingency'])}</td>
                <td>{renderNumber(answers['dfa']['contingency'])}</td>
              </tr>

              <tr>
                <td>Change Orders</td>
                <td>{renderNumber(answers['custom']['changeorders'])}</td>
                <td>{renderNumber(answers['dfa']['changeorders'])}</td>
              </tr>

              <tr>
                <td>Construction Budget</td>
                <td></td>
                <td></td>
              </tr>

              <tr data-bs-toggle="collapse" data-bs-target=".section-sc">
                <td>
                  <strong>Soft Costs</strong>
                  <span className="seemore">+</span>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr className='collapse section-sc subsection'>
                <td>Architect's Fee</td>
                <td>{renderNumber(answers['custom']['architectfee'])}</td>
                <td>{renderNumber(answers['dfa']['architectfee'])}</td>
              </tr>

              <tr className='collapse section-sc subsection'>
                <td>Mechanical Engineering</td>
                <td>{renderNumber(answers['custom']['mechanicalengineering'])}</td>
                <td>Included</td>
              </tr>

              <tr className='collapse section-sc subsection'>
                <td>DOB Fees</td>
                <td>{renderNumber(answers['custom']['dob'])}</td>
                <td>{renderNumber(answers['dfa']['dob'])}</td>
              </tr>

          </tbody>
        </table>
      </div>
    )
  }

  const renderMain = () => { 
    return (
      <div>
        <ul className="list-group">
          {questions.slice(currentIndex, currentIndex + 2).map((question, index) => (
            <li key={currentIndex + index} className="list-group-item">
              <h2>{question['title']}</h2>
                {question['type'] === "conditional" ? (
                  <div className="d-flex justify-content-center flex-wrap">
                    <div className="form-switch form check">
                      <input className="form-check-input" type="checkbox" onChange={(e) => handleCheckboxChange(question['questions'], e.target.checked)}/>
                    </div>
                    {renderQuestion(internalQuestions[question['questions']], true)}
                  </div>
                ) : (
                  <div className="d-flex justify-content-evenly flex-wrap">
                    {renderQuestion(question, false)}
                  </div>
                )}
              
            </li>
          ))}
        </ul>
        <button className="btn btn-primary mt-3 next" onClick={handleNext}>
          Next
        </button>
      </div>
    )
  }

  return (
    <div className="container mt-5 center">
      <h1 className="mb-4">DFA Studio Calculator</h1>
      {showTable ? ( renderCosts() ) : (renderMain())}
      {currentIndex > 0 ? (
        <button className="btn btn-primary mt-3 next" onClick={handleBack}>
          Back
        </button>
      ) : ( null )}
    </div>
  );
}

export default QuestionApp;